import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <span><a href="https://www.bisongrid.uk/freight-inspection-claims-software/" target="_blank" rel="noopener noreferrer">Freight Inspection</a> &copy; 2018 <a href="https://www.bisongrid.uk" target="_blank" rel="noopener noreferrer">Bison Grid Ltd.</a></span>
        {/* <span className="ml-auto">Version 1.0.0</span> */}
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
