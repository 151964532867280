import 'react-app-polyfill/ie11';
import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Reactotron from 'reactotron-react-js';
import get from 'lodash.get';

// import SessionContext, { initialState, login, logout } from './contexts/SessionContext';
import SessionContext, { initialState } from './contexts/SessionContext';
import history from './includes/history';

import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
// import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-pro/css/all.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css';

// Containers
import { DefaultLayout } from './containers';
// Pages
// import { Login, Page404, Page500, Register } from './views/Pages';
import { Login, Loading } from './views/Pages';

// import { renderRoutes } from 'react-router-config';

import './ReactotronConfig';

class App extends Component {
  login = (username, password) => {
    Reactotron.log('login');
    const { apiService } = this.state;

    this.setState({ loggingIn: true });

    return apiService.login(username, password)
      .then(() => apiService.user())
      .then((user) => {
        const token = apiService.token();
        this.setState({ token, user: user.data });
        const data = [
          ['username', username],
          ['user', JSON.stringify(user.data)],
          ['token', token],
        ];

        data.map(d => localStorage.setItem(d[0], d[1]));
        return null;
      })
      .catch((err) => {
        if (typeof err.response === 'undefined') Reactotron.log('API is currently unreachable');
        else Reactotron.log(err);
        return null;
      })
      .finally(() => this.setState({ loggingIn: false }));
  }

  logout = () => {
    Reactotron.log('logout');
    const { apiService } = this.state;

    return apiService.logout()
      .catch((err) => {
        if (typeof err.response === 'undefined') Reactotron.log('API offline');
        else Reactotron.log(err);
        return null;
      })
      .then(() => {
        const keys = ['user', 'token'];
        keys.map(d => localStorage.removeItem(d));
        this.setState({ user: null, token: null });
      });
  }

  getConfig(path, defaultValue) {
    if (!this.state.configuration) return {};
    return path
      ? get(this.state.configuration.config, path, defaultValue)
      : this.state.configuration || defaultValue;
  }

  loadAppConfig = () => this.downloadAppConfig()
    .then((configuration) => {
      if (!configuration) throw new Error('Could not load configuration');
      this.setState({ configuration });
      return true;
    })
    .catch(() => Promise.resolve());

  downloadAppConfig = () => new Promise((resolve) => {
    if (this.state.configuration) return this.state.configuration;
    const configuration = localStorage.getItem('configuration');
    if (!configuration) return resolve({});
    return resolve(JSON.parse(configuration));
  })
    .then((currentConfig) => {
      const { updatedAt } = currentConfig;
      const { apiService } = this.state;

      return apiService.downloadConfiguration('web', updatedAt)
        .then((response) => {
          localStorage.setItem('configuration', JSON.stringify(response.data));
          return response.data;
        })
        .catch(() => currentConfig);
    });

  onSessionExpired() {
    console.log('App.onSessionExpired()');
    return Promise.resolve(this.setState({ user: undefined }));
  }

  constructor(props) {
    super(props);

    this.state = {
      login: this.login,
      logout: this.logout,
      getConfig: this.getConfig.bind(this),
      history,
    };
  }


  componentDidMount = () => {
    const state = initialState(this.onSessionExpired.bind(this));
    this.setState(state);
    this.loadAppConfig();
  }

  render() {
    const { user } = this.state;

    const layout = this.state.configuration ? DefaultLayout : Loading;

    const supportsHistory = 'pushState' in window.history;

    return (
      <SessionContext.Provider value={this.state}>
        <Router forceRefresh={!supportsHistory} history={history}>
          <Switch>
            {/* <Route exact path="/login" name="Login Page" component={Login} />
            <Route exact path="/register" name="Register Page" component={Register} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} /> */}
            <Route path="/" name="Home" component={user ? layout : Login} />
          </Switch>
        </Router>
      </SessionContext.Provider>
    );
  }
}

export default App;
