import React from 'react';
import Reactotron from 'reactotron-react-js';

import ApiService from '../services/ApiService';

Reactotron.log('SessionContext');

const HOST = `//${window.location.host}`;
// const HOST = 'https://demo.containerinspection.app';
// const HOST = 'https://petrucela.freightinspection.app';
// const HOST = 'https://siemmafi.freightinspection.app';
// const HOST = 'https://siemshipping.freightinspection.app';
// const HOST = 'https://freight-dev1.bisongrid.com';
// const HOST = 'https://demo.freightinspection.app';
// const HOST = 'http://local.zmbe.net:3000';
// const HOST = 'http://192.168.1.157:3000';

export const initialState = (onSessionExpired) => {
  Reactotron.log('initialState');

  const state = {
    api: `${HOST}/api/v1`,
    photo: `${HOST}/photos`,
    downloads: `${HOST}/downloads`,
  };

  [['username'], ['token'], ['user', 'json']].forEach((item) => {
    const value = localStorage.getItem(item[0]);
    state[item[0]] = item[1] === 'json' ? JSON.parse(value) : value;
  });

  const apiService = new ApiService(state.api, null, state.token, null, null, onSessionExpired);
  state.apiService = apiService;

  return state;
};

export const login = (username, password) => {
  Reactotron.log('login');
  const { apiService } = this.state;

  return apiService.login(username, password)
    .then(() => apiService.user())
    .then((user) => {
      const token = apiService.token();
      this.setState({ token, user: user.data });
      const data = [
        ['username', username],
        ['user', JSON.stringify(user.data)],
        ['token', token],
      ];

      data.map(d => localStorage.setItem(d[0], d[1]));
      return null;
    });
};

export const logout = () => {
  Reactotron.log('logout');
  const { apiService } = this.state;

  return apiService.logout()
    .then(() => {
      const keys = ['user', 'token'];
      keys.map(d => localStorage.removeItem(d));
      this.setState({ user: null, token: null });
    });
};

const SessionContext = React.createContext({});

export default SessionContext;
