import React, { Component } from 'react';
import {
  Col,
  Container,
  Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSpinner, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { faSpinner, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

import SessionContext from '../../../contexts/SessionContext';

class Loading extends Component {
  static contextType = SessionContext;

  state = {
    message: 'Loading...',
  }

  render() {
    const { message } = this.state;

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="5" className="text-center">
              <FontAwesomeIcon icon={faSpinnerThird} spin size='2x'/>
            </Col>
          </Row>
          <Row><Col><br /></Col></Row>
          <Row className="justify-content-center">
            <Col md="5" className="text-center">
              <h4>{message}</h4>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Loading;
