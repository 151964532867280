import React, { Component } from 'react';
import Reactotron from 'reactotron-react-js';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';

import SessionContext from '../../../contexts/SessionContext';

class Login extends Component {
  static contextType = SessionContext;

  state = {
    username: '',
    password: '',
    // username: 'rich@bisongrid.uk',
    // password: 'Wp3MZMk3mueUt[B3',
  }

  componentDidMount = () => {
    const { username } = this.context;
    if (username) this.setState({ username });
  }

  render() {
    const { username, password } = this.state;
    const { login, loggingIn } = this.context;

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="5">
              {/* <CardGroup> */}
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Username"
                          autoComplete="username"
                          value={username}
                          onChange={e => this.setState({ username: e.target.value })}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          value={password}
                          onChange={e => this.setState({ password: e.target.value })}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="12">
                          <InputGroup invalid="true" className="mb-4">
                          <FormFeedback>Houston, we have a problem...</FormFeedback>
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <Button
                            color="primary"
                            className="px-4"
                            onClick={() => login(username, password)}
                            disabled={!username || !password || loggingIn}
                          >Login</Button>
                        </Col>
                        {/* <Col xs="6" className="text-right">
                          <Button color="link" className="px-0">Forgot password?</Button>
                        </Col> */}
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                {/* <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.</p>
                      <Button color="primary" className="mt-3" active>Register Now!</Button>
                    </div>
                  </CardBody>
                </Card> */}
              {/* </CardGroup> */}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
