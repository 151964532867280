import React from 'react';
import Loadable from 'react-loadable';

import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const Jobs = Loadable({
  loader: () => import('./views/Jobs'),
  loading: Loading,
});

const Job = Loadable({
  loader: () => import('./views/Jobs/Job'),
  loading: Loading,
});

const Inspections = Loadable({
  loader: () => import('./views/Inspections'),
  loading: Loading,
});

const Inspection = Loadable({
  loader: () => import('./views/Inspections/Inspection'),
  loading: Loading,
});

const Damages = Loadable({
  loader: () => import('./views/Damages'),
  loading: Loading,
});

const Damage = Loadable({
  loader: () => import('./views/Damages/Damage'),
  loading: Loading,
});

const Units = Loadable({
  loader: () => import('./views/Units'),
  loading: Loading,
});

const Unit = Loadable({
  loader: () => import('./views/Units/Unit'),
  loading: Loading,
});

const Transports = Loadable({
  loader: () => import('./views/Transports'),
  loading: Loading,
});

const Locations = Loadable({
  loader: () => import('./views/Locations'),
  loading: Loading,
});

const Clients = Loadable({
  loader: () => import('./views/Clients'),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import('./views/Users'),
  loading: Loading,
});

const Roles = Loadable({
  loader: () => import('./views/Roles'),
  loading: Loading,
});

const ImportTools = Loadable({
  loader: () => import('./views/ImportTools'),
  loading: Loading,
});

const ExportTools = Loadable({
  loader: () => import('./views/ExportTools'),
  loading: Loading,
});

const ToolsNewUnits = Loadable({
  loader: () => import('./views/Tools'),
  loading: Loading,
});

const ToolsUnitLogs = Loadable({
  loader: () => import('./views/Tools/UnitLogs'),
  loading: Loading,
});

const ToolsClearUnitLogs = Loadable({
  loader: () => import('./views/Tools/ClearUnitLogs'),
  loading: Loading,
});

const Reports = Loadable({
  loader: () => import('./views/Reports'),
  loading: Loading,
});

const Guides = Loadable({
  loader: () => import('./views/Guides'),
  loading: Loading,
});

const GuidesDashboard = Loadable({
  loader: () => import('./views/Guides/Dashboard'),
  loading: Loading,
});

const GuidesStatusTotals = Loadable({
  loader: () => import('./views/Guides/Dashboard/StatusTotals'),
  loading: Loading,
});

const GuidesSummaryAllocation = Loadable({
  loader: () => import('./views/Guides/Dashboard/SummaryAllocation'),
  loading: Loading,
});

const GuidesLastReported = Loadable({
  loader: () => import('./views/Guides/Dashboard/Reported'),
  loading: Loading,
});

const GuidesUnits = Loadable({
  loader: () => import('./views/Guides/Units'),
  loading: Loading,
});

const GuidesSorting = Loadable({
  loader: () => import('./views/Guides/Units/Sorting'),
  loading: Loading,
});

const GuidesFiltering = Loadable({
  loader: () => import('./views/Guides/Units/Filtering'),
  loading: Loading,
});

const GuidesPaging = Loadable({
  loader: () => import('./views/Guides/Units/Paging'),
  loading: Loading,
});

const GuidesExport = Loadable({
  loader: () => import('./views/Guides/Units/Export'),
  loading: Loading,
});

const GuidesTools = Loadable({
  loader: () => import('./views/Guides/Tools'),
  loading: Loading,
});

const GuidesBulkData = Loadable({
  loader: () => import('./views/Guides/Tools/BulkData'),
  loading: Loading,
});

const GuidesNewUnits = Loadable({
  loader: () => import('./views/Guides/Tools/BulkData/NewUnits'),
  loading: Loading,
});

const GuidesUnitLogs = Loadable({
  loader: () => import('./views/Guides/Tools/BulkData/UnitLogs'),
  loading: Loading,
});

const GuidesLeaseDetails = Loadable({
  loader: () => import('./views/Guides/Tools/BulkData/Lease'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/', exact: true, name: 'Home', component: DefaultLayout,
  },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  {
    path: '/jobs', exact: true, name: 'Jobs', component: Jobs,
  },
  {
    path: '/jobs/:id', exact: true, name: 'Job Details', component: Job,
  },
  {
    path: '/inspections', exact: true, name: 'Inspections', component: Inspections,
  },
  {
    path: '/inspections/:id', exact: true, name: 'Inspection Details', component: Inspection,
  },
  {
    path: '/damages', exact: true, name: 'Damages', component: Damages,
  },
  {
    path: '/damages/:id', exact: true, name: 'Damage Details', component: Damage,
  },
  {
    path: '/units', exact: true, name: 'Units', component: Units,
  },
  {
    path: '/units/:id', exact: true, name: 'Unit Details', component: Unit,
  },
  { path: '/transports', name: 'Transports', component: Transports },
  { path: '/locations', name: 'Locations', component: Locations },
  { path: '/clients', name: 'Clients', component: Clients },
  { path: '/users', name: 'Users', component: Users },
  { path: '/roles', name: 'Roles', component: Roles },
  {
    path: '/tools/newunits', name: 'New Units', exact: true, component: ToolsNewUnits,
  },
  {
    path: '/tools/unitlogs', name: 'Unit Logs', exact: true, component: ToolsUnitLogs,
  },
  {
    path: '/tools/clearunitlogs', name: 'Clear Unit Logs', exact: true, component: ToolsClearUnitLogs,
  },
  {
    path: '/reports', name: 'Report', component: Reports,
  },
  {
    path: '/guides', exact: true, name: 'Guides', component: Guides,
  },
  {
    path: '/guides/dashboard', exact: true, name: 'Dashboard', component: GuidesDashboard,
  },
  {
    path: '/guides/dashboard/statustotals', exact: true, name: 'Status Totals', component: GuidesStatusTotals,
  },
  {
    path: '/guides/dashboard/summary', exact: true, name: 'Summary Allocation', component: GuidesSummaryAllocation,
  },
  {
    path: '/guides/dashboard/reported', exact: true, name: 'Last Reported', component: GuidesLastReported,
  },
  {
    path: '/guides/units', exact: true, name: 'Units', component: GuidesUnits,
  },
  {
    path: '/guides/units/sorting', exact: true, name: 'Sorting', component: GuidesSorting,
  },
  {
    path: '/guides/units/filtering', exact: true, name: 'Filtering', component: GuidesFiltering,
  },
  {
    path: '/guides/units/paging', exact: true, name: 'Paging', component: GuidesPaging,
  },
  {
    path: '/guides/units/export', exact: true, name: 'Export', component: GuidesExport,
  },
  {
    path: '/guides/tools', exact: true, name: 'Tools', component: GuidesTools,
  },
  {
    path: '/guides/tools/bulkdata', exact: true, name: 'Bulk Data', component: GuidesBulkData,
  },
  {
    path: '/guides/tools/bulkdata/newunits', exact: true, name: 'New Units', component: GuidesNewUnits,
  },
  {
    path: '/guides/tools/bulkdata/unitlogs', exact: true, name: 'Unit Logs', component: GuidesUnitLogs,
  },
  {
    path: '/guides/tools/bulkdata/lease', exact: true, name: 'Lease Details', component: GuidesLeaseDetails,
  },
  // {
  //   path: '/import', name: 'Import', exact: true, component: ImportTools,
  // },
  // {
  //   path: '/export', name: 'Export', exact: true, component: ExportTools,
  // },
];

export default routes;
