export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'fas fa-tachometer-alt-fast',
    },
    // {
    //   name: 'Jobs',
    //   url: '/jobs',
    //   icon: 'fas fa-list-ul',
    // },
    // {
    //   name: 'Inspections',
    //   url: '/inspections',
    //   icon: 'far fa-check-square',
    // },
    // {
    //   name: 'Damages',
    //   url: '/Damages',
    //   icon: 'fas fa-exclamation-triangle',
    // },
    {
      name: 'Units',
      url: '/units',
      icon: 'fas fa-container-storage',
    },
    {
      name: 'Tools',
      url: '/tools',
      icon: 'fas fa-tools',
      children: [
        {
          name: 'Add unit(s)',
          url: '/tools/newunits',
          icon: 'far fa-layer-plus',
        },
        {
          name: 'Add unit log(s)',
          url: '/tools/unitlogs',
          icon: 'fas fa-file-plus',
        },
        {
          name: 'Clear unit log(s)',
          url: '/tools/clearunitlogs',
          icon: 'fas fa-file-minus',
        },
      ],
    },
    {
      name: 'Operations',
      url: '/base',
      icon: 'fas fa-cogs',
      children: [
        {
          name: 'Transports',
          url: '/transports',
          icon: 'fas fa-ship',
        },
        {
          name: 'Locations',
          url: '/locations',
          icon: 'fas fa-map-marked-alt',
        },
      ],
    },
    {
      name: 'Admin',
      url: '/base',
      icon: 'fas fa-shield-alt',
      children: [
        {
          name: 'Clients',
          url: '/clients',
          icon: 'fas fa-building',
        },
        {
          name: 'Users',
          url: '/users',
          icon: 'fas fa-user-friends',
        },
        // {
        //   name: 'Roles',
        //   url: '/roles',
        //   icon: 'fas fa-id-card-alt',
        // },
      ],
    },
    // {
    //   name: 'User Guides',
    //   url: '/guides',
    //   icon: 'fas fa-book',
    //   class: 'mt-auto',
    //   variant: 'info',
    // },
    // {
    //   name: 'Contact Support',
    //   url: 'mailto:support@bisongrid.uk',
    //   icon: 'fas fa-envelope',
    //   variant: 'success',
    // },
  ],
};
